import React from 'react'
import * as styles from '../../styles/product/product.module.scss'

const Pipe = ({ content, heading, bg }) => {
  return (
    <>
      <h3 className={`${bg === true? `${styles['devops__column__heading_primary']}` : `${styles['devops__column__heading_white']}`}`}>{heading}</h3>
      <div>
        {content !==  undefined && (
          <>
          {content.map((itm, idx) => (
            <div key={idx} className={`${idx === 0 ? `${styles['devops__column__row']}` : `${styles['devops__column__row']}`}
                ${bg ? `${styles['devops__column__bg']}` : ``}`}>
              {bg === true && (
              <img src={itm.img} alt={itm.img} />
              )}
              <span>{itm.text}</span>
            </div>
          ))}
          </>
          )}
      </div>
    </>
  )
}

export default Pipe
