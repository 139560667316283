import { Link } from "gatsby";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Lottie from "react-lottie-segments";
import "../../styles/product/style.scss";

// local import
import halfTriangle from "../../assets/images/common/half_triangle.svg";
import triangle from "../../assets/images/common/triangle.svg";
import Tick from "../../assets/images/homepage/tick.svg";
import FirstImg from "../../assets/images/product/first_img.svg";
import SecondImg from "../../assets/images/product/second_img.svg";
import ThirdImg from "../../assets/images/product/third_img.svg";
import FourthImg from "../../assets/images/product/fourth.svg";
import FifthImg from "../../assets/images/product/fifth.svg";
import * as animationData from "../../assets/images/product/aura.json";
import autoscaler from "../../assets/images/product/autoscaler.webm";
import first from "../../assets/images/product/first.svg";
import kubernative from "../../assets/images/product/kubernative.png";
import * as metricsData from "../../assets/images/product/metric.json";
import mongodb from "../../assets/images/product/mongo-db.png";
import mysql from "../../assets/images/product/mysql.png";
import play from "../../assets/images/product/play.svg";
import postgres from "../../assets/images/product/postgres.png";
import second from "../../assets/images/product/second.svg";
import sliderBg from "../../assets/images/product/bg.png";

import third from "../../assets/images/product/third.svg";
import WhiteTick from "../../assets/images/product/white_tick.svg";
import * as styles2 from "../../components/Common/InfraCarousal/InfraCarousal.module.scss";
import * as styles from "../../styles/product/product.module.scss";

import Carousel from "../../components/Common/Carousels";
import DemoLargeButton from "../../components/Common/DemoLargeButton";
import Layout from "../../components/Common/Layout";
import Seo from "../../components/Common/Seo";
import CarouselCard from "../../components/Product/CarouselCard";
import CostCalculator from "../../components/Product/CostCalculator";
import InstallationCard from "../../components/Product/InstallationCard";
import Pipe from "../../components/Product/Pipe";

const lottieSetting = (data) => {
  return {
    loop: true,
    autoplay: true,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
};

const startPoint = { value: 0, isFrame: true };

const Product = (props) => {
  const [hide, setHide] = useState(false);
  const pathName = props.location.pathname;

  const handleOpenVideo = () => {
    // const url = "https://www.youtube.com/watch?v=03pHqh1IC1s";
    // window.location.href = url;
    setHide(true);
  };
  return (
    <>
      <Layout pathName={pathName}>
        <Seo
          title="Lucidity Auto-scaler | Making you truly Cloud-ready"
          description="Lucidity Auto-scaler is an industry-first, state-of-the-art and autonomous multi-cloud block storage layer, making your AWS EBS and Azure managed disks economical, reliable and effortless."
          canonicalUrl="https://www.lucidity.cloud/product"
        />
        <section className={styles["aura"]}>
          <Container className={styles["aura__container"]}>
            <Row className="justify-content-center">
              <Col lg={9} xs={12} className="text-center p-0">
                <div
                  className={`${styles["aura__heading"]} justify-content-center`}
                >
                  <span>Lucidity</span>
                  <span>Auto-scaler</span>
                </div>
                <p className={`${styles["aura__subheading"]}`}>
                  Lucidity is the most trusted autonomous storage orchestration
                  solution for creating the block storage your data deserves.
                  Make your AWS EBS & Azure Managed Disks
                </p>
                <div
                  className={`${styles["aura__features"]} justify-content-center`}
                >
                  <div>
                    <img src={Tick} alt="tick" />
                    <span>Economical</span>
                  </div>

                  <div>
                    <img src={Tick} alt="tick" />
                    <span>Reliable</span>
                  </div>
                  <div>
                    <img src={Tick} alt="tick" />
                    <span>Effortless</span>
                  </div>
                </div>
                <div className={styles["aura__demobutton"]}>
                  <DemoLargeButton link="/book-demo" />
                </div>

                <div
                  className={styles["aura__autoscaler_video"]}
                  onClick={handleOpenVideo}
                >
                  {hide && (
                    <iframe
                      className={styles.frame}
                      src={`https://www.youtube.com/embed/03pHqh1IC1s?autoplay=1&modestbranding=1&autohide=1&showinfo=0`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      webkitallowfullscreen="true"
                      mozallowfullscreen="true"
                      allowFullScreen
                    />
                  )}
                  {!hide && (
                    <>
                      <video autoPlay loop muted>
                        <source src={autoscaler} type="video/mp4" />
                      </video>
                      <div className={styles["videoplay"]}>
                        <img src={play} alt="play" />
                      </div>
                    </>
                  )}
                </div>

                <div className={styles["aura__intro_heading"]}>
                  <h2>
                    Introducing Lucidity <span>Auto-Scaler</span>
                  </h2>
                  <p>
                    Lucidity sits between your cloud storage and applications
                    and creates an unobtrusive intelligence layer on top of any
                    block storage database, application server disks, or
                    Kubernetes. Retain your data on cloud storage and get the
                    benefit of cloud native functionality for your lift & shift
                    applications with zero code changes.{" "}
                  </p>
                  <Lottie
                    options={lottieSetting(animationData)}
                    height="auto"
                    width="100%"
                    goToAndPlay={startPoint}
                    playSegments={{
                      segments: [0, 86],
                      forceFlag: true,
                    }}
                  />
                </div>
              </Col>
            </Row>

            <Row className={styles["aura__intro"]}>
              <Col lg={5} className={styles["aura__intro__col2"]}>
                <div className={styles["aura__intro__col2__feature"]}>
                  <div className={styles["head"]}>
                    <img src={WhiteTick} alt="tick" />
                    <p>Zero downtime</p>
                  </div>
                  <p className={styles["subhead"]}>
                    {" "}
                    Never run out of disk space during surge{" "}
                  </p>
                </div>
                <div className={styles["aura__intro__col2__feature"]}>
                  <div className={styles["head"]}>
                    <img src={WhiteTick} alt="tick" />
                    <p>70% Cost Reduction</p>
                  </div>
                  <p className={styles["subhead"]}>
                    Increase disk utilization from 35% to 80% and reduce cost
                    overheads by upto 70%
                  </p>
                </div>
                <div className={styles["aura__intro__col2__feature"]}>
                  <div className={styles["head"]}>
                    <img src={WhiteTick} alt="tick" />
                    <p>Save DevOps effort</p>
                  </div>
                  <p className={styles["subhead_last"]}>
                    Automate capacity planning and provisioning with 3 clicks.
                  </p>
                </div>
              </Col>
              <Col lg={7} className="my-auto p-0">
                <Lottie
                  options={lottieSetting(metricsData)}
                  height="auto"
                  width="100%"
                  goToAndPlay={startPoint}
                />
              </Col>
            </Row>
          </Container>
        </section>

        <section className={styles["leaders"]}>
          <Container className={styles["leaders__container"]}>
            <Row className={styles["leaders__row"]}>
              <Col
                lg={5}
                xs={12}
                sm={12}
                className={`${styles["leaders__col1"]}  my-auto`}
              >
                <h3>
                  For <span>Infrastructure Leaders</span>
                </h3>
                <p>
                  Find out how much you can save, and achieve tangible and
                  instant ROI in next 1 hour
                </p>
                <Link to="/calculator" className={styles["advance_button"]}>
                  Advanced mode
                </Link>
              </Col>
              <Col lg={7} xs={12} sm={12} className={styles["leaders__col2"]}>
                <CostCalculator />
              </Col>
            </Row>
          </Container>
        </section>

        <section className={styles["devops"]}>
          <Container className={styles["devops__container"]}>
            <Row>
              <Col lg={4} xs={12} className="order-2">
                <Pipe
                  heading={"Before Lucidity"}
                  content={[
                    { text: "Disk Utilization not available" },
                    { text: "Online upgrade requires manual interventions" },
                    { text: "Shrinking disk requires downtime" },
                    { text: "30% IOPS degrade during upgrade" },
                    { text: "6h gap mandatory between consecutive expansion" },
                  ]}
                  bg={false}
                />
              </Col>
              <Col lg={4} xs={12} className="order-3">
                <Pipe
                  heading={"After Lucidity"}
                  content={[
                    {
                      img: FirstImg,
                      text: "Disk Utilization Metrics available on Lucidity",
                    },
                    {
                      img: SecondImg,
                      text: "Automated upgrade with zero touch",
                    },
                    {
                      img: ThirdImg,
                      text: "Automatically shrink disk without downtime",
                    },
                    {
                      img: FourthImg,
                      text: "Zero IOPS impact during upgrade",
                    },
                    { img: FifthImg, text: "Multiple expansions possible" },
                  ]}
                  bg={true}
                />
              </Col>
              <Col
                lg={4}
                xs={12}
                className={`${styles["devops__desc"]} order-1`}
              >
                <h3>
                  DevOps made easy on <span>AWS and Azure</span>
                </h3>
                <p>
                  Transform from DevOps to NoOps smart storage. With zero-touch
                  requirements, eliminate the need of any manual intervention or
                  downtime.
                </p>

                <div className={styles["devops__desc__btn"]}>
                  <DemoLargeButton link="/book-demo" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className={`${styles2["teamlove"]} ${styles["postchange"]}`}>
          <Container className={styles2["teamlove__container"]}>
            <Row className="pe-0 position-relative">
              <Col lg={7} className="p-0 order-2 order-lg-1">
                <div
                  className={`${styles2["teamlove__shortline"]} ${styles["postchange__shortline"]}`}
                ></div>
                <Carousel
                  slideCount={3}
                  slideWidth={348}
                  slideTime={3000}
                  leftAlign={42}
                  smWidth={250}
                  classNames2={styles["slider_list"]}
                  classNames1={styles["carousel__inner"]}
                  isScale={true}
                >
                  <CarouselCard
                    heading={"Application Server Disks"}
                    subheading={`Eliminate capacity guesswork while provisioning application server disks. Let Lucidity Auto-scaler decide the smart default & then scale up & down for you, based on your data requirements. Infact, get Lucidity baked into your instance AMI and enjoy the convenience of optimized block storage from day 1.`}
                    leftImg={halfTriangle}
                  />
                  <CarouselCard
                    heading={"Databases"}
                    subheading={`Stop worrying about continuously monitoring databases for disk space, IOPS  & then resorting to unscientific and tedious ways of predicting disk requirements. Instead, let Lucidity take the pain of automating monitoring, planning and capacity provisioning. In other words, you save costs without ever being bothered on downtime.`}
                    leftImg={triangle}
                    logo={[mysql, mongodb, postgres]}
                  />
                  <CarouselCard
                    heading={"Kubernetes"}
                    subheading={`Simplify your container volume management with Kubernetes. Let Lucidity optimize your storage costs by optimal volume allocation, scaling, scheduling and monitoring of these containers, while you focus on building your containerized applications and services.`}
                    leftImg={halfTriangle}
                    logo={[kubernative]}
                  />
                </Carousel>
              </Col>
              <Col lg={5} className="order-1 order-lg-2 position-relative">
                <img
                  src={sliderBg}
                  alt="sliderBg"
                  className={styles["postchange__bg_img"]}
                />
                <h2 className={styles["postchange__heading"]}>
                  How will Application storage needs{" "}
                  <span>change post Lucidity</span>
                </h2>
              </Col>
            </Row>
          </Container>
        </section>

        <section className={`${styles["setup"]}`}>
          <Container>
            <Row className={`${styles["setup__row"]} `}>
              <Col lg={12}>
                <h2 className="text-center">
                  Three steps to start{" "}
                  <span>saving up to 70% of your cloud</span>
                </h2>
              </Col>
              <Col
                xs={12}
                className="p-0 d-block d-md-block d-sm-none d-lg-none"
              >
                <Carousel
                  slideCount={3}
                  slideWidth={348}
                  slideTime={3000}
                  leftAlign={64}
                  smWidth={290}
                  classNames2={styles["slider_list"]}
                  classNames1={styles["carousel__inner"]}
                  isScale={false}
                >
                  <InstallationCard
                    title={"Step 1 "}
                    primaryTxt={"Register"}
                    img={first}
                  />
                  <InstallationCard
                    title={"Step 2 "}
                    primaryTxt="Onboard"
                    img={second}
                  />
                  <InstallationCard
                    title={"Step 3 "}
                    primaryTxt="Relax"
                    img={third}
                  />
                </Carousel>
              </Col>

              <Col
                lg={4}
                md={4}
                className="d-none d-md-none d-sm-block  d-lg-block"
              >
                <InstallationCard
                  title={"Step 1 "}
                  primaryTxt={"Register"}
                  img={first}
                />
              </Col>
              <Col
                lg={4}
                md={4}
                className="d-none d-md-none d-sm-block d-lg-block"
              >
                <InstallationCard
                  title={"Step 2 "}
                  primaryTxt="Onboard"
                  img={second}
                />
              </Col>
              <Col
                lg={4}
                md={4}
                className="d-none d-md-none d-sm-block d-lg-block"
              >
                <InstallationCard
                  title={"Step 3 "}
                  primaryTxt="Relax"
                  img={third}
                />
              </Col>
            </Row>
          </Container>
        </section>
      </Layout>
    </>
  );
};

export default Product;
