// extracted by mini-css-extract-plugin
export var advance_button = "product-module--advance_button--iHPa0";
export var aura = "product-module--aura--ZynIF";
export var aura__autoscaler_video = "product-module--aura__autoscaler_video--WDodh";
export var aura__container = "product-module--aura__container--Wt3Ys";
export var aura__demobutton = "product-module--aura__demobutton--QQhD1";
export var aura__features = "product-module--aura__features--xUzfd";
export var aura__heading = "product-module--aura__heading--pTVfu";
export var aura__intro = "product-module--aura__intro--N5HXu";
export var aura__intro__col2 = "product-module--aura__intro__col2--UCq3-";
export var aura__intro__col2__feature = "product-module--aura__intro__col2__feature--lOZab";
export var aura__intro_heading = "product-module--aura__intro_heading--cY8UG";
export var aura__subheading = "product-module--aura__subheading--8M81E";
export var carousel__inner = "product-module--carousel__inner--7ixOm";
export var devops = "product-module--devops--YVsj8";
export var devops__column__bg = "product-module--devops__column__bg--DBbW7";
export var devops__column__heading_primary = "product-module--devops__column__heading_primary--VZUFc";
export var devops__column__heading_white = "product-module--devops__column__heading_white--77YMC";
export var devops__column__row = "product-module--devops__column__row--cmSfX";
export var devops__desc = "product-module--devops__desc--+z3dQ";
export var devops__desc__btn = "product-module--devops__desc__btn--B689G";
export var doller = "product-module--doller--r35XI";
export var frame = "product-module--frame--rF7CP";
export var graph_result = "product-module--graph_result--YWqUH";
export var head = "product-module--head--M6+ai";
export var leaders__calc = "product-module--leaders__calc--+JGcM";
export var leaders__calc__graph = "product-module--leaders__calc__graph--24qPL";
export var leaders__calc__graph__container = "product-module--leaders__calc__graph__container--Ne6ck";
export var leaders__calc__input = "product-module--leaders__calc__input--DZ2Xy";
export var leaders__calc__input__provisioned = "product-module--leaders__calc__input__provisioned--DTHeq";
export var leaders__calc__input__utilisation = "product-module--leaders__calc__input__utilisation--uso6o";
export var leaders__col1 = "product-module--leaders__col1--0LblM";
export var leaders__col2 = "product-module--leaders__col2--WhJrG";
export var leaders__container = "product-module--leaders__container--0ZsaZ";
export var leaders__row = "product-module--leaders__row--UeWMx";
export var postchange = "product-module--postchange--xfn4A";
export var postchange__bg_img = "product-module--postchange__bg_img--akiQp";
export var postchange__card = "product-module--postchange__card--9P7cU";
export var postchange__card__bottom = "product-module--postchange__card__bottom--usRVQ";
export var postchange__card__logo = "product-module--postchange__card__logo--MuDp4";
export var postchange__heading = "product-module--postchange__heading--GVdTJ";
export var postchange__shortline = "product-module--postchange__shortline--kf0j3";
export var pro_label = "product-module--pro_label--kHWqx";
export var setup = "product-module--setup--uRI28";
export var setup__row = "product-module--setup__row--P0FOm";
export var setup__wrapper = "product-module--setup__wrapper--xEcCJ";
export var slider = "product-module--slider--vZ3mk";
export var slider_list = "product-module--slider_list--9q8R9";
export var slider_wrapper = "product-module--slider_wrapper--fCM3c";
export var subhead = "product-module--subhead--M4sEQ";
export var subhead_last = "product-module--subhead_last--W9jfn";
export var videoplay = "product-module--videoplay--vysoT";