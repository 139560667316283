// extracted by mini-css-extract-plugin
export var slider_list = "InfraCarousal-module--slider_list--gMblE";
export var teamlove = "InfraCarousal-module--teamlove--RJy8L";
export var teamlove__carousel__card = "InfraCarousal-module--teamlove__carousel__card--9JEd6";
export var teamlove__carousel__card__bottom = "InfraCarousal-module--teamlove__carousel__card__bottom--EAIbJ";
export var teamlove__circel = "InfraCarousal-module--teamlove__circel--bTxGx";
export var teamlove__col1 = "InfraCarousal-module--teamlove__col1--JZHfw";
export var teamlove__col2 = "InfraCarousal-module--teamlove__col2--2DCFz";
export var teamlove__container = "InfraCarousal-module--teamlove__container--+UHub";
export var teamlove__heading = "InfraCarousal-module--teamlove__heading--6RAgw";
export var teamlove__line = "InfraCarousal-module--teamlove__line--b-tSV";
export var teamlove__shortline = "InfraCarousal-module--teamlove__shortline--5Kcy4";