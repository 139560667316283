import React, { useState, useEffect } from "react";

import * as styles from "../../styles/product/product.module.scss";
import afterGraph from "../../assets/images/product/saving_graphs.svg";
import highGraph from "../../assets/images/product/cylinder.svg";
import info from "../../assets/images/calculator/info.svg";

const CostCalculator = () => {
  const [provisioned, setProvisioned] = useState(150);
  const [utilisation, setUtilisation] = useState(30);
  const [totalCost, setTotalCost] = useState(0);
  const [lucidityCost, setLucidityCost] = useState(0);
  const [saving, setSaving] = useState(0);
  const costMultiplier = 1024 * 0.119;
  const slider = (e) => {
    setUtilisation(e.target.value);
  };
  const getProvisioned = (e) => {
    setProvisioned(e.target.value);
  };

  useEffect(() => {
    setTotalCost(provisioned * costMultiplier);
    setLucidityCost(((provisioned * utilisation) / 100 / 0.8) * costMultiplier);
  }, [utilisation, provisioned]);

  useEffect(() => {
    let save = ((totalCost - lucidityCost) / totalCost) * 100;
    setSaving(save < 0 ? 0 : save);
  }, [totalCost, lucidityCost]);

  return (
    <div className={styles["leaders__calc"]}>
      <div className={styles["leaders__calc__input"]}>
        <h4>Enter details</h4>

        <label htmlFor="provisioned" className="form-label">
          Disk provisioned
        </label>
        <div className={styles["leaders__calc__input__provisioned"]}>
          <input
            type="number"
            className="form-control"
            id="provisioned"
            min="1"
            value={provisioned}
            onChange={getProvisioned}
          ></input>
          <p>TB</p>
        </div>

        <div className={styles["pro_label"]}>
          <label
            htmlFor="provisioned"
            className="form-label position-relative w-50"
          >
            Disk utilisation
          </label>
          <div className="d-flex align-items-center gap-2">
            <img src={info} alt="info"></img>
            {utilisation > 49 && <p>Sure? Industry average is 35%</p>}
            {utilisation > 29 && utilisation <= 49 && (
              <p>Yup! Industry average is 35%</p>
            )}
            {utilisation > 0 && utilisation <= 29 && (
              <p>Oh? Industry average is 35%</p>
            )}
          </div>
        </div>
        <div className={styles["leaders__calc__input__utilisation"]}>
          <div className={styles.slider}>
            <div className={styles.doller}>
              <span>%</span>
            </div>
            <p>{utilisation}</p>
            <div className={styles.slider_wrapper}>
              <input
                id="vol"
                name="vol"
                min="0"
                max="100"
                onChange={slider}
                type="range"
                value={utilisation}
                style={{
                  background: `linear-gradient(90deg, #DDFF55 ${utilisation}%, #707070 ${
                    Number(utilisation) + 0.1
                  }%)`,
                }}
              ></input>
            </div>
          </div>
        </div>
      </div>
      <div className={styles["leaders__calc__graph"]}>
        <h4>Calculate ROI</h4>
        <div className={`${styles["leaders__calc__graph__container"]}`}>
          <div>
            <label>Before</label>
            <img src={highGraph} height={117} alt="before" />
            <span
              style={{
                display: `${
                  (utilisation && provisioned) > 0 ? "block" : "none"
                }`,
              }}
            >
              $
              {utilisation > 0
                ? totalCost.toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                  })
                : 0}
            </span>
          </div>
          <div className="align-self-end">
            <label>After</label>
            <img src={afterGraph} alt="after" />
            <span
              style={{
                display: `${
                  (utilisation && provisioned) > 0 ? "block" : "none"
                }`,
              }}
            >
              $
              {lucidityCost.toLocaleString(undefined, {
                maximumFractionDigits: 0,
              })}
            </span>
          </div>
        </div>
        <div className={styles["graph_result"]}>
        {(utilisation && provisioned) > 0 && (
          <p>
            Save {utilisation > 0 ? saving.toFixed(1) | "" : " "}% cost in the
            next 1 hour
          </p>
        )}
        </div>
      </div>
    </div>
  );
};
export default CostCalculator;
