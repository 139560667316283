import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "gatsby"

// local
import * as styles from "./DemoButton.module.scss";

const DemoLargeButton = ({ text, link }) => {
  return (
    <>
      <Link to={link ? link : '/book-demo'}>
        <Button variant="outlined" className={styles["demo_button_large"]}>
          {text ? text : "Book a Demo"}
        </Button>
      </Link>
    </>
  );
};

export default DemoLargeButton;
