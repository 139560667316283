import React from 'react'
import * as styles from '../../styles/product/product.module.scss'

const InstallationCard = ({title, img, primaryTxt}) => {
  return (
    <div className={styles['setup__wrapper']}>
        <h4>{title}<span>{primaryTxt}</span></h4>
        <img src={img} alt={img} />
    </div>
  )
}

export default InstallationCard