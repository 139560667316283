import React from 'react'

// local import
import * as styles from "../../styles/product/product.module.scss"
import * as styles2 from "../Common/InfraCarousal/InfraCarousal.module.scss"

const CarouselCard = ({heading, subheading, leftImg, logo}) => {
  return (
    <>
    <div className={`${styles2['teamlove__carousel__card']} ${styles['postchange__card']}`}>
        <p>{heading}</p>
        <p>{subheading}</p>
        <div className={styles['postchange__card__bottom']}>
            <img src={leftImg} alt={leftImg}/>
            {logo !== undefined && (
              <div className={styles['postchange__card__logo']} >
              {logo.map((img, idx) => {
                return (<img key={idx} src={img} alt={img} />)
              })  }
              </div>
           )}
        </div>
    </div>
    </>
  )
}

export default CarouselCard;